<template>
  <b-overlay :show="loading" rounded="sm">
	<div class="h-100" id="root">
		<div class="d-flex">
			<div style="width:300px" class="p-1">
				<b>Business Type:</b><b-form-select v-model="selected_type" :options="product_list.map(e => e.businessType).filter((e, i, a) => a.indexOf(e) === i && e !== 'For Testing').sort().map(e => ({value: e, text: e}))"></b-form-select>
			</div>
			<div style="width:300px" class="p-1">
				<b>Product:</b><b-form-select v-model="selected_product" :options="product_list.filter(e => e.businessType === selected_type).sort().map(e => ({value: e.productId, text: e.productName}))" @change="loadDailyVolumes(selected_product)"></b-form-select>
			</div>
			<div style="width:300px" class="p-1">
				<b>Port Pair:</b><b-form-select v-model="selected_port_pair" :options="volumes.pair"></b-form-select>
			</div>
			<div class="p-1 flex-grow-1">
				<button type="button" style="width:200px" class="btn p-2" :class="{'btn-success':show_weight, 'btn-primary':!show_weight}" @click="show_weight = !show_weight"><span v-if="show_weight">Show Parcel Count</span><span v-else>Show Parcel Weight</span></button>
			</div>
		</div>
		<table class="bg-white">
			
			<template v-for="(d, j) in volumes.row">
				<template v-if="j % 28 == 0">
					<tr>
						<th style="width:100px;" class="border text-center text-white p-1" :class="{'bg-success':show_weight, 'bg-primary':!show_weight}" rowspan="2">PreReg. Date</th>
						<th style="padding:5px" class="border bg-light text-white" :class="{'bg-success':show_weight, 'bg-primary':!show_weight}" v-for="(v, i) in volumes.col" :key="i" colspan="3">{{ getCompanyName(v) }}</th>
					</tr>
					<tr>
						<template v-for="(v, i) in volumes.col">
							<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">PreReg. Count</td>
							<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">PreReg. Weight</td>
							<template v-if="selected_type == 'End to End'">
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">Inbound Count</td>
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">Inbound Weight</td>
							</template>
							<template v-else-if="selected_type == 'Import CC + LM'">
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">ATA Count</td>
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">ATA Weight</td>
							</template>
							<template v-else>
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">A-Scan Count</td>
								<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">A-Scan Weight</td>
							</template>
							<td style="width:80px;" class="border text-right" :class="{'bg-light': i % 2 == 1}">Backlog Count %</td>
						</template>
					</tr>
				</template>
				<tr>
					<th style="padding:5px" class="border text-center text-white" :class="{'bg-success':show_weight, 'bg-primary':!show_weight}">{{ d }}</th>
					<template v-for="(v, i) in volumes.col">
						<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">
							<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
								{{ formatCont(volumes.data[selected_port_pair][d][v].totalPreregisteredCount) }}
							</span>
						</td>
						<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">
							<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
								{{ formatWeight(volumes.data[selected_port_pair][d][v].totalPreregisteredWeight) }} kg
							</span>
						</td>
						<!-- end to end -->
						<template v-if="selected_type == 'End to End'">
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatCont(volumes.data[selected_port_pair][d][v].totalInboundCount) }}
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatWeight(volumes.data[selected_port_pair][d][v].totalInboundWeight) }} kg
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatPec(volumes.data[selected_port_pair][d][v].totalInboundCount, volumes.data[selected_port_pair][d][v].totalPreregisteredCount) }}
								</span>
							</td>
						</template>
						<!-- Import CC + LM -->
						<template v-else-if="selected_type == 'Import CC + LM'">
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatCont(volumes.data[selected_port_pair][d][v].totalAtaCount) }}
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatWeight(volumes.data[selected_port_pair][d][v].totalAtaWeight) }} kg
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatPec(volumes.data[selected_port_pair][d][v].totalAtaCount, volumes.data[selected_port_pair][d][v].totalPreregisteredCount) }}
								</span>
							</td>
						</template>
						<!-- Return or LM only -->
						<template v-else>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="!show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatCont(volumes.data[selected_port_pair][d][v].totalReceivedCount) }}
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}" v-if="show_weight">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatWeight(volumes.data[selected_port_pair][d][v].totalReceivedWeight) }} kg
								</span>
							</td>
							<td style="padding:5px" class="border text-right" :class="{'bg-light': i % 2 == 1}">
								<span v-if="volumes.data[selected_port_pair] && volumes.data[selected_port_pair][d] && volumes.data[selected_port_pair][d][v]">
									{{ formatPec(volumes.data[selected_port_pair][d][v].totalReceivedCount, volumes.data[selected_port_pair][d][v].totalPreregisteredCount) }}
								</span>
							</td>
						</template>
					</template>
				</tr>
			</template>
		</table>
	</div>
  </b-overlay>
</template>

<script>
	import { BFormSelect, BOverlay } from 'bootstrap-vue'
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import sources from "@configs/sources";

	export default {
		components: {
			BFormSelect,
			BOverlay
		},
  		data () {
    		return {
				volumes: {
					data: [],
					row: [],
					col: [],
					pair: []
				},
				product_list : [],
				client_list : [],
				selected_product : "2",
				selected_type : "End to End",
				selected_port_pair: "",
				show_weight: false,
				loading: false
			}
		},
		mounted () {
			this.loadProduct();
		},
		methods: {
			async loadProduct () {
				this.loading = true;
				this.product_list = await fetcher(apis.kParcelProductList, FetchMethod.GET);
				this.client_list = await fetcher(apis.listClient, FetchMethod.GET, {source: sources.TOMS});
				this.loading = false;
				this.loadDailyVolumes(this.selected_product);
			},
			async loadDailyVolumes(productId) {
				this.loading = true;
				const response = await fetcher(apis.kParcelDailyVolumes + "?productId=" + productId, FetchMethod.GET);
				this.volumes.row = response.map(e => e.date).filter((e, i, a) => a.indexOf(e) === i).sort().reverse();
				this.volumes.col = response.map(e => e.clientId).filter((e, i, a) => a.indexOf(e) === i).sort();
				this.volumes.pair = response.map(e => e.fromCountry + " -> " + e.toCountry).filter((e, i, a) => a.indexOf(e) === i).sort().map(e => ({value: e, text: e}));
				this.selected_port_pair = this.volumes.pair.length > 0 ? this.volumes.pair[0].value : "";
				let data = [];
				response.forEach(e => {
					const pair = e.fromCountry + " -> " + e.toCountry;
					if (!data[pair]) {
						data[pair] = [];
					}
					if (!data[pair][e.date]) {
						data[pair][e.date] = [];
					}
					data[pair][e.date][e.clientId] = e;
				});
				this.volumes.data = data;
				this.loading = false;
			},
			formatWeight(w) {
				if (w < 10000)
					return Math.round(w * 100 / 1000) / 100;
				else
					return (Math.round(w / 1000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			formatCont(w) {
				return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
			formatPec(a, b) {
				if (a === 0 || b === 0)
					return "0.00%";
				return Math.round((1 - a / b) * 10000) / 100 + "%";
			},
			getCompanyName(id) {
				const r = this.client_list.filter(e => e.clientId === id);
				return r.length > 0 ? r[0].companyName : "";
			}
		}
	};
</script>

<style scoped>

</style>